<template>
    <div>
        <div v-if="isAuthenticated" class="layout">
            <OrganismAppHeader />
            <div v-if="!isLoading" id="my-account" class="my-account container">
                <OrganismBreadcrumbs :items="breadcrumbs" />

                <template v-if="userData">
                    <AtomAccountGreeting
                        v-if="showTitle"
                        :customer-name="`${userData.firstName} ${userData.lastName}`"
                    />
                    <MoleculeAccountTabs
                        v-model:active-index="activeIndexOverwrite"
                        :items="tabItems"
                        :class="{
                            'account-tabs': true,
                            'account-micro-frontend-carts-tabs': !showTitle,
                        }"
                        :line-style="true"
                        @tab-changed="update"
                    />
                    <slot v-if="userData?.id" />
                </template>
            </div>
            <AtomSpinner v-if="isLoading" />
            <OrganismAppFooter class="account-footer" />
            <OrganismMatrixModal />
            <MoleculeToasts />
            <MoleculeTransfers />
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { BreadcrumbsItem } from '~/composables/types/api/searchDiscover/categoryTreeTypes'
import { NuxtLinkNameTypes } from '~/composables/types/nuxtHyperlinkTypes'

const { isAuthenticated, getUserData, userData, logout } = useUser()
const { $can, $t, $hasRole, $userFlow } = useNuxtApp()
const title = ref('')
const route = useRoute()
const toasts = useToasts()
const isLoading = ref(false)
const config = useRuntimeConfig()

if (!isAuthenticated.value) {
    logout()
    if (!config.public.oAuthEnabled) {
        window.location.href = '/'
    }
}

interface TabItem {
    link: string
    icon: string
    activeIndex: number
}

const tabItems: Ref<TabItem[]> = ref([
    { link: 'addresses', icon: 'home', activeIndex: 0 },
    { link: 'order-history', icon: 'shopping-cart', activeIndex: 1 },
    { link: 'order-history-smo', icon: 'shopping-cart', activeIndex: 2 },
    { link: 'carts', icon: 'basket', activeIndex: 3 },
    { link: 'condition-sheets', icon: 'conditions', activeIndex: 4 },
    { link: 'branch-clusters', icon: 'cluster', activeIndex: 5 },
    { link: 'order-details', icon: '', activeIndex: 1 },
    { link: 'order-details-smo', icon: '', activeIndex: 2 },
])

if ($hasRole(RoleTypes.SUPPLIER)) {
    tabItems.value = [
        { link: 'order-history', icon: 'shopping-cart', activeIndex: 0 },
        { link: 'order-history-smo', icon: 'shopping-cart', activeIndex: 1 },
        { link: 'carts', icon: 'basket', activeIndex: 2 },
        { link: 'evaluation', icon: 'download', activeIndex: 3 },
    ]
}

if (!$can(PermissionTypes.ORDER) && !$hasRole(RoleTypes.MEMBER)) {
    tabItems.value = [{ link: 'order-history', icon: 'shopping-cart', activeIndex: 1 }]
}

const activeTabIndex = ref(0)
const activeIndexOverwrite = ref(0)
const activeTab = ref<TabItem>()
const breadcrumbs: Ref<BreadcrumbsItem[]> = ref([{}])

useHead({
    title: title,
})
const showTitle = computed(() => activeTab.value?.link !== 'carts')

const setBreadCrumbs = () => {
    breadcrumbs.value = [
        {
            name: `Vororder Portal`,
            link: `/`,
        },
        {
            name: $t(`General.${activeTab.value?.link.replaceAll('-', '_')}`),
            link: `/${activeTab.value?.link}`,
        },
    ]
}

const setActiveTab = () => {
    activeTabIndex.value = tabItems.value.findIndex(
        (x: any) => route.path === `${NuxtLinkNameTypes.ACCOUNT_PAGE}/${x.link}`
    )
    activeTab.value = tabItems.value[activeTabIndex.value]
    activeIndexOverwrite.value = activeTab.value?.activeIndex

    if (route.path === NuxtLinkNameTypes.ACCOUNT_PAGE || route.path === `${NuxtLinkNameTypes.ACCOUNT_PAGE}/`) {
        navigateTo({
            path: NuxtLinkNameTypes.ORDER_HISTORY_PAGE_SMO,
        })
    }

    setBreadCrumbs()
    title.value = $t(`General.${activeTab.value?.link.replaceAll('-', '_')}`)
}

const update = (i: number) => {
    navigateTo({
        path: `${NuxtLinkNameTypes.ACCOUNT_PAGE}/${tabItems.value[i].link}`,
    })
}

const redirectTo = (event: Event) => {
    if ('detail' in event) {
        navigateTo(event.detail?.toString())
    }
}

watch(
    () => route.path,
    () => {
        setActiveTab()
    }
)

onMounted(async () => {
    isLoading.value = true

    window.addEventListener('redirectTo', redirectTo)
    window.dataLayer = window.dataLayer || []

    if (!activeTabIndex.value) {
        setActiveTab()
    }

    await getUserData()

    if (!userData.value?.id) {
        toasts.add('LOAD_ERROR')

        return
    }

    if (config.public.userCentricsIsActive) {
        useUsercentricsCookieConsents()
    }

    $userFlow?.loadUserFlow()
    isLoading.value = false
})

onBeforeUnmount(() => {
    window.removeEventListener('redirectTo', redirectTo)
})
</script>

<style lang="scss" scoped>
.account-footer {
    margin-top: rem(56);
}

.account-tabs {
    margin-bottom: rem(32);

    &:deep(.text) {
        max-width: rem(144);
    }

    &:deep(.account-tabs__item) {
        min-width: rem(192);
    }
}

.account-micro-frontend-carts-tabs {
    margin: 0 0 rem(32);
}
</style>
